import React from 'react'
import { graphql } from 'gatsby'
import Modal from 'react-modal'
import { Seo } from '../components/base'
import { NewsletterForm } from '../components/forms'
import { LayoutBackground } from '../components/shared'
import { Layout } from '../components'

Modal.setAppElement('#___gatsby')

const NewsletterPage = ({ data, location, pageContext }) => {
  const { desc, seo, headline, subline, newsletterForm } =
    data.sanityNewsletterPage.nodes[0]
  const languages = { pageContext }
  const layoutBackgroundHeaderComponent = (
    <div className="mb-16">
      <h1 className="mb-2">{headline}</h1>
      <div className="uppercase text-xl">{subline}</div>
    </div>
  )

  return (
    <Layout
      standardPaddingTopFromNavbarToMain
      reducedFooter
      translations={languages}>
      <Seo
        title={seo?.metaTitle}
        description={seo?.metaDescription}
        image={seo?.ogImage?.asset?.url}
      />
      <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
        <div className="md:px-10 lg:px-0 max-w-6xl mx-auto">
          <p className="text-base">{desc}</p>

          <NewsletterForm
            formData={newsletterForm}
            markets={data.markets.nodes}
            email={location?.state?.email}
          />
        </div>
      </LayoutBackground>
    </Layout>
  )
}

export default NewsletterPage

export const myNewsletter = graphql`
  query ($language: String! = "de_de") {
    markets: allSanityMarket(filter: { status: { in: ["9"] } }) {
      nodes {
        _id
        marketId
        countryCode
        name
        address {
          city
          street
          zip
          additionalAddress
        }
      }
    }
    sanityNewsletterPage: allSanityNewsletterPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        desc
        seo {
          ...SeoQuery
        }
        headline
        subline
        newsletterForm {
          emailAddress {
            name
            placeholder
            required
            type
            initialValue
          }
          privacy {
            name
            _rawLabel
            required
            initialValue
          }
        }
      }
    }
  }
`
